<template>
<span>

    <template>
        <v-layout row justify-end>
            <v-dialog v-model="dialog_foto" persistent max-width="600px">
                <v-card>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <img v-if="stor_or_it_foto" :src="`${base_url}upload/product_photo/g/${stor_or_it_foto}`" class="foto-ampliada">
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-grey darken-1" @click="dialog_foto = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </template>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">
            <h3 v-if="storeorder.user">{{ storeorder.user.usu_nome }}</h3>
        </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        Pedido Nº: {{ storeorder.stor_or_codigo }}
        <v-spacer></v-spacer>
        <template>
            <v-btn color="grey darken-2" dark class="mb-1" @click.prevent="loadStoreOrder">
                <v-icon dark>sync</v-icon>Atualizar
            </v-btn>
            <v-btn color="blue-grey" dark class="mb-1 ml-2" :to="{name: 'store-orders'}">
                <v-icon dark>keyboard_arrow_left</v-icon> Voltar
            </v-btn>
        </template>
    </v-toolbar>

    <v-card class="grey darken-4 mt-1" v-if="storeorder.acompanha">
        <template>
            <v-layout>
                <v-flex d-flex justify-space-between mx-5>
                    <v-switch color="lime" v-model="storeorder.acompanha.stor_of_recebido" class="my-switch" label="Pedido Recebido" :readonly="storeorder.acompanha.stor_of_recebido"></v-switch>
                    <v-switch color="lime" v-model="storeorder.acompanha.stor_of_faturado" @change="onChangeEventHandlerFaturado(storeorder.user.usu_id, storeorder.acompanha.stor_of_id, $event)" class="my-switch" label="Pedido Faturado" :readonly="storeorder.acompanha.stor_of_faturado"></v-switch>
                    <v-switch color="lime" v-model="storeorder.acompanha.stor_of_liberado" @change="onChangeEventHandlerLiberado(storeorder.user.usu_id, storeorder.acompanha.stor_of_id, $event)" class="my-switch" label="Pedido Liberado" :readonly="storeorder.acompanha.stor_of_liberado"></v-switch>
                    <v-switch color="lime" v-model="storeorder.acompanha.stor_of_encaminhado" @change="onChangeEventHandlerEncaminhado(storeorder.user.usu_id, storeorder.acompanha.stor_of_id, $event)" class="my-switch" label="Pedido Encaminhado" :readonly="storeorder.acompanha.stor_of_encaminhado"></v-switch>
                    <v-switch color="lime" v-model="storeorder.acompanha.stor_of_entregue" @change="onChangeEventHandlerEntregue(storeorder.user.usu_id, storeorder.acompanha.stor_of_id, $event)" class="my-switch" label="Pedido Entregue" :readonly="storeorder.acompanha.stor_of_entregue"></v-switch>
                </v-flex>
            </v-layout>
        </template>
    </v-card>

    <table id="table-detalhe" class="table table-bordered table-condensed">
        <tbody>
            <tr>
                <th width="10%">Pedido Nº:</th>
                <td width="90%" colspan="7">{{ storeorder.stor_or_codigo }}</td>
            </tr>
            <tr>
                <th>Forma de Pagamento:</th>
                <td colspan="2">{{ storeorder.stor_or_forma_pagto }}</td>
                <th>ID Transação:</th>
                <td colspan="2">{{ storeorder.stor_or_id_transacao }}</td>
                <th>TID:</th>
                <td colspan="2">{{ storeorder.stor_or_tid }}</td>
            </tr>
            <tr>
                <th>Data:</th>
                <td colspan="3">{{ storeorder.stor_or_data }}</td>
                <th>Situação Pedido:</th>
                <td colspan="3">{{ storeorder.stor_or_situacao }}</td>
            </tr>
            <tr>
                <th>Situação Pagamento:</th>
                <td colspan="3">{{ storeorder.stor_or_situacao_pagto }}</td>
                <th>Cod. Rastreio:</th>
                <td colspan="3">{{ storeorder.stor_or_cod_rastreio }}</td>
            </tr>
            <tr class="grey darken-4">
                <td colspan="8">DADOS DO COMPRADOR</td>
            </tr>
            <tr v-if="storeorder.user">
                <th>Nome:</th>
                <td colspan="2">{{ storeorder.user.usu_nome }}</td>
                <th>CPF/CNPJ:</th>
                <td colspan="2">{{ (storeorder.user.usu_cpf != '') ? storeorder.user.usu_cpf : storeorder.user.usu_cnpj }}</td>
                <th>RG/IE:</th>
                <td colspan="2">{{ (storeorder.user.usu_rg != '') ? storeorder.user.usu_rg : storeorder.user.usu_ie }}</td>
            </tr>
            <tr v-if="storeorder.user">
                <th>Telefone:</th>
                <td colspan="2">{{ (storeorder.user.usu_fone1 ) ? storeorder.user.usu_prefixo : '' }} {{ storeorder.user.usu_fone1 }}</td>
                <th>Celular:</th>
                <td colspan="2">{{ (storeorder.user.usu_celular ) ? storeorder.user.usu_prefixo : '' }} {{ storeorder.user.usu_celular }}</td>
                <th>E-mail:</th>
                <td colspan="2">{{ storeorder.user.email }}</td>
            </tr>
            <tr class="grey darken-4">
                <td colspan="8">DADOS DE ENTREGA</td>
            </tr>
            <tr>
                <th>CEP:</th>
                <td colspan="2">{{ storeorder.stor_or_cep_entrega }}</td>
                <th>Endereço:</th>
                <td colspan="2">{{ storeorder.stor_or_endereco_entrega }}</td>
                <th>Número:</th>
                <td colspan="2">{{ storeorder.stor_or_num_entrega }}</td>
            </tr>
            <tr>
                <th>Complemento:</th>
                <td colspan="2">{{ storeorder.stor_or_complemento_entrega }}</td>
                <th>Bairro:</th>
                <td colspan="2">{{ storeorder.stor_or_bairro_entrega }}</td>
                <th>Cidade/UF:</th>
                <td colspan="2">{{ storeorder.stor_or_cidade_entrega }} - {{ storeorder.stor_or_uf_entrega }}</td>
            </tr>
            <tr class="grey darken-4">
                <td colspan="8">ITENS DESSE PEDIDO</td>
            </tr>
        </tbody>
    </table>
    <v-card>
        <v-data-table :headers="headers" :items="storeorder.itens" hide-default-footer>
            <template v-slot:[`item.stor_or_it_foto`]="{ item }">
                <td width="15%" v-if="item.stor_or_it_foto"><img :src="`${base_url}upload/product_photo/p/${item.stor_or_it_foto}?${uuid}`" class="avatar" @click="ampliarFoto(item.stor_or_it_foto)"></td>
                <td width="15%" v-if="!item.stor_or_it_foto"><img :src="`${base_url}upload/logo.png`" class="avatar"></td>
            </template>

        </v-data-table>
        <h3 class="valor-parcial text-right teal--text lighten-1">Valor Parcial: R$ {{ storeorder.total_parcial }}</h3>
        <h3 class="frete text-right white--text lighten-1">Frete: R$ {{ storeorder.stor_or_valor_frete }} +</h3>
        <h3 class="desconto text-right white--text lighten-1">Desconto: R$ {{ storeorder.stor_or_desconto }} -</h3>
        <h2 class="total-pedido text-right lime--text lighten-1">Total Pedido: R$ {{ storeorder.total }}</h2>
    </v-card>

</span>
</template>

<script>
import {
    URL_BASE,
    IS_MOBILE
} from '../../../config/configs'
import {
    uuid
} from "vue-uuid";

export default {
    name: "DetailStoreOrderComponent",
    created() {
        this.loadStoreOrder();
    },
    computed: {

    },
    props: {
        stor_or_id: {
            require: true
        },
    },
    data() {
        return {
            stor_or_it_foto: null,
            dialog_foto: false,
            search: '',
            headers: [{
                    sortable: false,
                    text: 'Foto',
                    value: 'stor_or_it_foto'
                },
                {
                    sortable: false,
                    align: 'center',
                    text: 'Cod. Item',
                    value: 'stor_or_it_cod_item'
                },
                {
                    sortable: false,
                    align: 'center',
                    text: 'Referência',
                    value: 'stor_or_it_referencia'
                },
                {
                    sortable: false,
                    text: 'Nome',
                    value: 'stor_or_it_nome'
                },
                {
                    sortable: false,
                    align: 'center',
                    text: 'Qde',
                    value: 'stor_or_it_qde'
                },
                {
                    sortable: false,
                    align: 'right',
                    text: 'Valor',
                    value: 'stor_or_it_valor'
                },
                {
                    sortable: false,
                    align: 'right',
                    text: 'Subtotal',
                    value: 'stor_or_it_subtotal'
                },
            ],
            storeorder: {},
            base_url: URL_BASE,
            isMobile: IS_MOBILE(),
            uuid: uuid.v1()
        }
    },
    methods: {
        ampliarFoto(foto) {
            this.stor_or_it_foto = foto;
            this.dialog_foto = true;
        },
        loadStoreOrder() {
            this.$store.dispatch('loadStoreOrder', this.stor_or_id)
                .then(response => {
                    this.storeorder = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
        onChangeEventHandlerFaturado(user_id, stor_of_id, value) {
            this.$store.dispatch('updateStoreOrderFollowFaturado', {
                    stor_of_id: stor_of_id,
                    user_id: user_id,
                    stor_of_faturado: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: 'Sucesso',
                        text: 'Status do Pedido atualizado com sucesso',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Opssss!',
                        text: 'Erro ao atualizar status do Pedido',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
        onChangeEventHandlerLiberado(user_id, stor_of_id, value) {
            if (this.storeorder.acompanha.stor_of_faturado) {
                this.$store.dispatch('updateStoreOrderFollowLiberado', {
                        stor_of_id: stor_of_id,
                        user_id: user_id,
                        stor_of_liberado: value
                    })
                    .then(() => {
                        this.$swal({
                            position: "center",
                            icon: "success",
                            title: 'Sucesso',
                            text: 'Status do Pedido atualizado com sucesso',
                            showConfirmButton: true,
                            timer: 6000
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            position: "center",
                            icon: "error",
                            title: 'Opssss!',
                            text: 'Erro ao atualizar status do Pedido',
                            showConfirmButton: true,
                            timer: 6000
                        });
                    })
            } else {
                this.loadStoreOrder();
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Opssss! Não pode pular etapas',
                    text: 'Verifique os Status anteriores',
                    showConfirmButton: true,
                    timer: 6000
                });
            }
        },
        onChangeEventHandlerEncaminhado(user_id, stor_of_id, value) {
            if (this.storeorder.acompanha.stor_of_liberado) {
                this.$store.dispatch('updateStoreOrderFollowEncaminhado', {
                        stor_of_id: stor_of_id,
                        user_id: user_id,
                        stor_of_encaminhado: value
                    })
                    .then(() => {
                        this.$swal({
                            position: "center",
                            icon: "success",
                            title: 'Sucesso',
                            text: 'Status do Pedido atualizado com sucesso',
                            showConfirmButton: true,
                            timer: 6000
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            position: "center",
                            icon: "error",
                            title: 'Opssss!',
                            text: 'Erro ao atualizar status do Pedido',
                            showConfirmButton: true,
                            timer: 6000
                        });
                    })
            } else {
                this.loadStoreOrder();
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Opssss! Não pode pular etapas',
                    text: 'Verifique os Status anteriores',
                    showConfirmButton: true,
                    timer: 6000
                });
            }
        },
        onChangeEventHandlerEntregue(user_id, stor_of_id, value) {
            if (this.storeorder.acompanha.stor_of_encaminhado) {
                this.$store.dispatch('updateStoreOrderFollowEntregue', {
                        stor_of_id: stor_of_id,
                        user_id: user_id,
                        stor_of_entregue: value
                    })
                    .then(() => {
                        this.$swal({
                            position: "center",
                            icon: "success",
                            title: 'Sucesso',
                            text: 'Status do Pedido atualizado com sucesso',
                            showConfirmButton: true,
                            timer: 6000
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            position: "center",
                            icon: "error",
                            title: 'Opssss!',
                            text: 'Erro ao atualizar status do Pedido',
                            showConfirmButton: true,
                            timer: 6000
                        });
                    })
            } else {
                this.loadStoreOrder();
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Opssss! Não pode pular etapas',
                    text: 'Verifique os Status anteriores',
                    showConfirmButton: true,
                    timer: 6000
                });
            }
        }
    }
};
</script>

<style scoped>
.avatar {
    height: 25px;
}

.logo {
    height: 50px;
}

.verde {
    color: chartreuse;
}

.vermelho {
    color: tomato;
}

.frete {
    padding: 0 1rem .5rem 0;
    margin: 1rem 0 0 0;
    font-weight: 300;
}

.desconto {
    padding: 0 1rem .5rem 0;
    margin: 0;
    border-bottom: thin solid #666;
    font-weight: 300;
}
.valor-parcial{
    border-top: thin solid #666;
    padding: .5rem 1rem .5rem 0;
}
.total-pedido {
    padding: .5rem 1.5rem 1rem 0;
    margin: 0;
}

.foto-ampliada {
    max-width: 100%
}
</style>
